<template>
  <button >{{ name }}</button>
</template>

<script>

export default {
    props:{
        name: {
            type: String
        }
    }
}
</script>

<style scoped>
    button {
        background-color: #314257;
        width: 300px;
        height: 60px;
        border: none;
        border-radius: 15px;
        color: #fff;
        font-size: 25px;
        font-weight: 700;
        text-transform: uppercase;
        box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.5)
    }

    button:hover {
        cursor: pointer;
        transform: scale(.9);
        transition: .3s;
    }
</style>