<template>
  <Header />
  <h1>Conditions générales de ventes</h1>

  <h2>Article liminaire définitions</h2>
  <p>
    « LE CLIENT » désigne la personne ou la société pour qui la société 4 CANA
    réalise une prestation, ou son représentant désigné pour le suivi des
    travaux. « L’OFFRE » désigne le devis émis par 4 CANA. « LE CONTRAT »
    désigne l’ensemble des documents contractuels signés et acceptés par les
    deux parties « LA SOCIETE » ou « LE PRESTATAIRE » désigne la société 4 CANA.
    « LES PRESTATIONS » désignent les travaux réalisés par la société 4 CANA.
  </p>

  <h3>Acceptation des conditions générales de ventes</h3>
  <p>
    En signant le devis, le CLIENT accepte les présentes conditions générales et
    s’engage à les respecter. Les présentes conditions générales ont valeur
    d’accord obligatoire. La société 4CANA se réserve le droit de réviser ou de
    mettre à jour les présentes Conditions générales de vente. Toute
    modification apportée à celles-ci entrera en vigueur au jour de leur
    publication sur le site web.
  </p>
  <ol>
    <li class="title">Devis</li>

    <ol>
      <li>
        Toute demande d’intervention entrainera l’établissement d’un devis. Nos
        devis sont valables mois, sauf stipulation particulière indiquée sur le
        devis. A l’expiration de ce délai, le devis sera caduc et le CLIENT
        devra contacter la société 4 CANA afin d’établir un nouveau devis.
      </li>
      <li>Nos études sont gratuites.</li>
      <li>
        Les études, plans et documents établis par nos services et confiés à la
        clientèle restent la propriété exclusive de l’entreprise 4CANA: ils ne
        peuvent donc donner lieu ni à la communication, ni à l’exécution sans
        autorisation écrite.
      </li>
      <li>
        Nos devis et études préalables tiennent compte du fait que les travaux
        seraient exécutés par notre Société; en conséquence, notre
        responsabilité ne saurait être engagée si les travaux sont confiés à un
        tiers.
      </li>
      <li>
        Le planning de travaux sera stipulé sur le devis. Il ne s’agit d’un
        délai prévisionnel de sorte que la société 4 CANA ne saurait être tenue
        responsable en cas de retard d’exécution lié à un cas de force majeure,
        conditions météorologiques, au fait d'un tiers ou encore en cas de
        retard imputable aux délais de livraison du matériel.
      </li>
      <li>
        La société 4 CANA ne saurait être tenue responsable des erreurs commises
        par le CLIENT ou d’omission de sa part lors de l’acceptation du devis.
      </li>
      <li>
        L’intervention de la société 4 CANA n’inclue pas la remise en état
        nécessaire des ouvrages, équipements ou tout autre élément dont le
        déplacement ou la destruction sera nécessaire pour exécuter la
        prestation (exemple: dalle, pelouse, terrasse…).
      </li>
    </ol>

    <li class="title">Commande</li>

    <ol>
      <li>
        Les devis sont considérés comme acceptés après qu’ils nous soient
        retournés signés, accompagnés d’un chèque de caution de 50% avant
        travaux.
      </li>
      <li>
        Toute passation d’ordre implique la connaissance parfaite de nos
        conditions générales de ventes auxquelles la clientèle adhère sans
        réserve ni restriction.
      </li>
      <li>
        Le client fournira à la commande, des indications précises permettant
        l’exécution des travaux sans recherche inutiles par nos équipes; dans le
        cas contraire une facturation supplémentaire sera appliquée en fonction
        du temps passé.
      </li>
    </ol>

    <li class="title">Execution</li>
    <ol class="subtitle">
      <li>
        Les délais indiqués au devis sont indicatifs, ils pourront être modifiés
        en cas de force majeur, de difficultés de circulation, de grèves,
        d’incendie ou vol du matériel ou plus généralement, de toute raison
        indépendante de notre volonté, les retards ne peuvent en aucun cas
        motiver une demande de dommages et intérêts, ni l’annulation de la
        commande.
      </li>
      <li>
        Resteront à la charge du client toutes fournitures d’énergie, d’eau et
        d’électricité nécessaires aux travaux et aux premiers essais ainsi que
        les travaux relevant des autres corps d’état sauf convention
        particulière.
      </li>
      <li>
        Le client veille au libre accès des installations en respectant les
        règles de sécurité. A défaut, les travaux ne seront pas exécutés et une
        facture de dédommagement sera établie par nos soins.
      </li>
      <li>
        Notre société reste libre de refuser la poursuite des travaux en cas de
        sujétion nouvelle présentant des difficultés n’entrant pas dans le cadre
        de ses compétences ou de ses moyens ou susceptibles d’être
        préjudiciables à son personnel ou à son matériel.
      </li>
    </ol>

    <li class="title">Facturation</li>
    <ol>
      <li>La facturation sera établie à la fin des travaux.</li>
      <li>
        Si les délais d’exécution sont supérieurs à un mois, nous présenterons
        des situations mensuelles payables à la réception, le solde sera facturé
        à la fin des travaux et payables à la réception de cette facture.
      </li>
      <li>Toute heure commencée sera facturée en totalité.</li>
      <li>
        Si les ouvrages présentent un vice de construction, une vétusté ou une
        obstruction tel qu’ils empêchent l’exécution des travaux, la
        facturation, correspondant aux moyens mis en œuvre et au temps passé,
        sera établie en tout état de cause.
      </li>
      <li>
        Toute intervention d’urgence, dont les délais ne permettent pas
        d’établir un devis préalable, sera facturée sur la base des tarifs en
        vigueur au moment de la commande. A défaut de commande écrite la
        signature préalable de l’ordre du travail vaut commande du client et
        acceptation des présentes conditions générales de vente.
      </li>
    </ol>

    <li class="title">Règlement</li>
    <ol>
      <li>
        A l’exception des clients particuliers pour lequel les prestations sont
        payables le jour de l’intervention, les factures seront réglées sous un
        délai de quarante-cinq (45) jours fin de mois date d’émission de la
        facture. Tout retard de paiement entrainera le règlement, d’une part, de
        pénalités de retard au taux de trois fois le taux d’intérêt général en
        vigueur, d’autre part, d’une indemnité forfaitaire pour frais de
        recouvrement d’un montant de 40 euros.
      </li>
      <li>
        En cas de sinistre, les factures demeureront exigibles; aucune
        compensation de quelque nature que ce soit ne pourra être faite.
      </li>
      <li>
        De convention expresse et sauf report sollicité à temps et accordé par
        nous, le défaut de paiement de nos factures à l’échéance fixée
        entraînera l’exigibilité immédiate de toutes les sommes restant dues
        quel que soit le mode de règlement prévu ( par traite acceptée ou non).
      </li>
      <li>
        Tous les frais, sans exception, engagés par la Société pour le
        recouvrement amiable ou contentieux des sommes impayées en capital,
        intérêts et frais, seront à la charge du client.
      </li>
      <li>
        Conformément à l’article L.441-6 du code du commerce, des pénalités de
        retard sont dues à défaut de règlement le jour suivant la date de
        paiement qui figure sur la facture. Le taux de ces pénalités est de 20%
        par an.
      </li>
      <li>
        Le vendeur se réserve la propriété des marchandises désignées sur ce
        document, jusqu’au paiement intégral de leur prix en principal et
        intérêts, frais et accessoires. A défaut de paiement du prix à
        l’échéance convenue, le vendeur pourra reprendre les marchandises et les
        acomptes déjà versés lui resteront acquis en contrepartie de la
        jouissance des marchandises dont aura bénéficié l’acheteur.
      </li>
    </ol>

    <li class="title">Assurance</li>
    <li class="only">
      La Société 4 CANA est assurée pour la garantie de ses risques
      professionnels en France Métropolitaine. Elle met à disposition du client,
      sur demande, l’ensemble de ses informations.
    </li>

    <li class="title">Contestations</li>
    <ol>
      <li>
        Les réclamations éventuelles doivent nous parvenir, par lettre
        recommandée, au plus tard 10 jours après la fin des travaux, passé ce
        délai, nous déclinons toute responsabilité quant aux conséquences
        directes ou indirectes de notre intervention.
      </li>
      <li>
        Passé ce délai, le client ne pourra pas refuser ou différer le règlement
        de sa facture pour quelque cause que ce soit. Le non-respect de cette
        clause entraînera de plein droit de mise en demeure et fera courir
        l’article F-5 de nos conditions générales de ventes.
      </li>
      <li>
        Les conditions générales portées sur les confirmations, correspondances,
        imprimés … de nos clients ne peuvent, en aucun cas, être opposés aux
        nôtres et, de ce fait, prévaloir
      </li>
    </ol>

    <li class="title">Déchets</li>
    <ol>
      <li>
        L’attention du client producteur de déchets est attirée sur sa propre
        responsabilité telle qu’elle a été définie par les dispositions
        législatives et réglementaires relatives à l’élimination de déchets.
      </li>
      <li>
        Les conséquences financières de l’évolution de la réglementation ou des
        modifications des filières de traitement ou d’élimination, seront
        répercutées aux clients, sans préavis.
      </li>
    </ol>

    <li class="title">Exclusions de responsabilité</li>
    <ol>
      <li>
        Les dommages aux canalisations ou aux tiers, résultant de la vétusté ou
        des vices cachés des ouvrages ne nous seront pas imputables.
      </li>
      <li>
        Des opérations telles que l’ouverture et la fermeture de fosses,
        regards, citernes … ne nous incombent pas sauf convention particulière.
        Dans le cas où nous serions dans l’obligation de procéder à des
        opérations, nous ne pourrons pas être tenus pour responsables des
        détériorations pouvant affectées notamment des tampons, plaques de trou
        d’homme, canalisations, tuyauteries ou tous accessoires … ainsi que des
        conséquences résultant de ce travail, même s’il est facturé en sus.
      </li>
      <li>
        La société 4 CANA ne pourra être tenue responsable des dommages causés
        aux accès (pelouse, dallages…). La remise en état nécessaire après
        l’intervention de la société 4 CANA sera à la charge exclusive du
        CLIENT.
      </li>
    </ol>

    <li class="title">
      Droit applicable et juridiction compétente, indépendance des clauses
    </li>
    <li class="only">
      Les présentes Conditions Générales sont soumises au droit français et à la
      compétence des Tribunaux judicaires Français. Toute tolérance ou
      renonciation d'une des Parties, dans l'application de tout ou partie des
      engagements prévus aux présentes, quelles qu’en soient la fréquence et la
      durée, ne saurait valoir modification des présentes, ni générer un droit
      quelconque. Dans l’hypothèse où l’une des clauses, stipulées aux
      présentes, serait frappée de nullité, le reste des conditions générales
      d’utilisation demeureraient pleinement applicables.
    </li>

    <li class="title">Données personnelles</li>
    <li class="only">
      Les informations recueillies par la société 4 CANA sont enregistrées dans
      un fichier informatisé par [la société 4 CANA pour les besoins de
      l’exécution de la prestation commandée. La base légale du traitement est
      l’exécution du contrat de prestation de service. Les données collectées
      seront communiquées aux seuls destinataires suivants : les salariés de la
      société 4 CANA Les données sont conservées pendant toute la durée
      d’exécution du contrat et cinq ans après. Vous pouvez accéder aux données
      vous concernant, les rectifier, demander leur effacement ou exercer votre
      droit à la limitation du traitement de vos données. Pour exercer ces
      droits ou pour toute question sur le traitement de vos données dans ce
      dispositif, vous pouvez contacter:
    </li>

    <p class="single">
      Monsieur PORCU Christophe<br />
      Tel 06.40.64.26.99<br />
      Mail: cchristopheporcu@gmail.com
    </p>
    <p class="single">
      Vous pouvez également consultez le site cnil.fr pour plus d’informations
      sur vos droits. Si vous estimez, après nous avoir contactés, que vos
      droits « Informatique et Libertés » ne sont pas respectés, vous pouvez
      adresser une réclamation à la CNIL.
      <br />Fait à MONTFAUCON le 30/09/2022
    </p>
  </ol>
  <Footer />
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style scoped>
h1 {
  margin: 80px;
  font-size: 50px;
}

p {
  width: 1200px;
  text-align: left;
  margin: 0 auto;
  font-size: 25px;
}

li {
  text-align: left;
  width: 1000px;
  margin: 15px auto;
  font-size: 25px;
}

.only {
  list-style: none;
}

.single {
  text-align: center;
  margin: 40px auto;
}

.title {
  list-style-type: upper-latin;
  margin: 20px auto;
  text-transform: uppercase;
  font-weight: 700;
} 


@media screen and (max-width: 1250px) {
  h1 {
    font-size: 40px;
    margin: 30px;
  }

  p {
    width: 100%;
    text-align: center;
  }

  li {
    width: 100%;
  }
}
</style>