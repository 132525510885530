<template>
    <div class="accueil_container">
        <Header />
        <Presentation />
        <Services />
        <Fournisseurs />
        <Realisations />
        <Team />
        <Footer />
    </div>
</template>

<script>
import Header from '../components/Header.vue'
import Presentation from '../components/Presentation.vue'
import Services from '../components/Services.vue'
import Fournisseurs from '../components/Fournisseurs.vue';
import Realisations from "../components/Realisations.vue";
import Team from '@/components/Team.vue';
import Footer from '../components/Footer.vue'

export default {
    components: {
        Header,
        Footer,
        Presentation,
        Services,
        Realisations,
        Team,
        Fournisseurs
    }
}
</script>

<style scoped>
    .accueil_container {
        background-color: #314257;
    }
</style>