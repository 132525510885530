<template>
  <div class="team">
    <div class="presentation">
        <h2>Qui sommes-nous ?</h2>
        <p>Notre équipe se tient à votre disposition pour vos demandes !</p>
        <router-link to="/equipe"><ButtonGrey name="Découvrir l'équipe !"/></router-link>
    </div>
    <img :src="require('../assets/images/image0.jpeg')" alt="Photo de l'équipe 4Cana">

  </div>
</template>

<script>
import ButtonGrey from './ButtonGrey.vue';

export default {
components: {
    ButtonGrey
}
}
</script>

<style scoped>
    .team {
        background-color: #fff;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 100px;
        height: 500px;
        align-items: center;    
    }

    img {
        height: 100%;
    }
    
    p , h2{
        color: black;
    }

    h2 {
        font-size: 35px;
        font-weight: 700;
    }

    p {
        font-size: 25px;
        width: 600px;
    }

    @media screen and (max-width: 1450px) {
        img {
            height: auto;
            width: 100%;
        }

        .team {
            display: flex;
            flex-direction: column;
            height: auto;
        }

        p {
            width: 100%;
        }

        h2 {
            font-size: 30px;
        }
    }
</style>