<template>
<div class="services">
    <h2>Bienvenue sur le site 4Cana !</h2>
    <p>
      Fort d’une expérience de plusieurs années dans le domaine, nous vous
      proposons de réhabiliter vos <strong>canalisations</strong> ou vos conduites de filtration de
      piscine <strong>sans tranchée et sans casse !</strong>
    </p>
    <h2>Nos services</h2>
    <Carousel />
    <router-link :to="'/services'"><ButtonGrey name="Découvrir nos services"/></router-link>
</div>
</template>

<script>
import Carousel from '../components/Carousel.vue'
import ButtonGrey from './ButtonGrey.vue';

export default {
    components: {
        Carousel,
        ButtonGrey
    }
};
</script>

<style scoped>
    .services {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
        margin-bottom: 60px;
    }

    h2 {
        font-size: 35px;
        text-transform: uppercase;
    }

    p {
        width: 1000px;
        font-size: 25px;
    }

    @media screen and (max-width: 1000px) {
        p {
            width: 90%;
        }
    }
</style>