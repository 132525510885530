<template>
<div class="supply-container">
  <h2>Nos fournisseurs</h2>
    <div class="img-container">
      <img :src="require('@/assets/images/fds-logo-1545131829.png')" alt="Logo FDS">
      <img :src="require('@/assets/images/brawoliner-logo.jpg')" alt="Logo Brawliner">
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .img-container {
      display: flex;
      justify-content: center;
      gap: 100px;
      background-color: #fff;
    }

    img {
        max-width: 350px;
        background-color: #fff;
    }

    h2 {
        text-transform: uppercase;
        color: #fff;
    }

    @media screen and (max-width: 700px) {
      .img-container {
        gap: 0;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
</style>