<template>
    <header>
        <router-link to="/"><img alt="Logo 4Cana Bleu" :src="require('../assets/images/Fichier 2@3x.png')"></router-link>
        <nav>
            <router-link to="/services"><h4>Nos services</h4></router-link>
            <span>|</span>
            <router-link to="/equipe"><h4>Notre équipe</h4></router-link>
            <span>|</span>
            <router-link to="/realisations"><h4>Nos réalisations</h4></router-link>
            <span>|</span>
            <router-link to="/contact"><h4>Contact</h4></router-link>
        </nav>
    </header>
</template>

<script>
export default {

}
</script>

<style scoped>

    header {
        min-height: 21vh;
        padding: 10px;
        box-shadow: 12px 3px 9px -7px black;
    }

    img {
        width: 300px;
        height: 120px;
        border-radius: 25px;
        box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.5);
        padding: 15px;
        margin: 15px;
        background-color: #fff;
    }

    nav {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 100px;
        font-size: 20px;
    }

    span {
        align-self: center;
        font-weight: 100;
        color: black;
    }

    a {
        text-decoration: none;
        color: white;
    }

    @media screen and (max-width: 1200px) {
        nav {
            display: flex;
            flex-wrap: wrap;
            gap: 30px;
        }
    }

    @media screen and (max-width: 700px) {
        span {
            display: none;
        }
        
        nav {
            gap: 20px;
        }
        
        h4 {
            margin: 8px;
        }
    }
</style>