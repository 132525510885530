<template>
  <div class="realisations">
    <div class="video">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/2Zb4f31xpWc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
    <div class="logoBtn">
        <img :src="require('/Users/clement/Desktop/4CanaWebsite/4cana/src/assets/images/décennale.png')" alt="Logo garantie décénnale">
        <router-link to="/realisations"><ButtonGrey name="Voir nos réalisations"/></router-link>
    </div>
  </div>
</template>

<script>
import ButtonGrey from './ButtonGrey.vue';
export default {
    components: {
        ButtonGrey
    }
}
</script>

<style scoped>
    .realisations {
        margin-top: 100px;
        margin-bottom: 100px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        gap: 100px;
    }

    iframe {
        width: 800px;
        height: 450px;
    }

    .logoBtn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
    }

    img {
        width: 350px;
        height: 300px;
    }

    @media screen and (max-width: 800px) {
        iframe {
            width: 100%;
        }

        .video {
            width: 100%;
        }
    }

    @media screen and (max-width: 550px){
        img {
            width: 250px;
            height: 200px;
        }
    }
</style>