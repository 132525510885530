import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'

const vueApp = createApp(App);

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faInstagram, faTiktok, faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons'

library.add(faInstagram, faTiktok, faLinkedin, faFacebook)

vueApp.use(router)
vueApp.component('font-awesome-icon', FontAwesomeIcon).mount('#app')
