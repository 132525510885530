<template>
    <div class="presentation">
        <h1>LA SOLUTION POUR VOS CANALISATIONS SANS TRANCHÉE</h1>
        <router-link to="/contact"><ButtonBlue name="Contactez-nous"/></router-link>
    </div>
</template>

<script>
import ButtonBlue from './ButtonBlue.vue';

export default {
    components: {
        ButtonBlue
    },
    data(){
        return{
        }
    }
}
</script>

<style scoped>
    .presentation {
        background-image: url('@/assets/images/Chemisage\ DN100\ EP.jpg');
        background-position: 50% 25%;
        height: 800px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    h1 {
        font-size: 60px;
    }

    @media screen and (max-width: 700px) {
        h1 {
            font-size: 45px;
        }
    }
</style>