<template>
  <div class="footer-container">
    <img alt="Logo 4Cana Bleu" :src="require('../assets/images/Fichier 2@3x.png')">
    <nav>
        <router-link to="/">Accueil</router-link>
        <router-link to="/cgv">Conditions générales de vente</router-link>
        <router-link to="/mentions">Mentions légales</router-link>
        <router-link to="/contact">Contact</router-link>
    </nav>
    <h4 class="mail">Email: sarl4cana@gmail.com</h4>
    <div class="social">
        <h4>Suivez-nous sur nos réseaux:</h4>
        <div class="logos">
            <a href="https://instagram.com/4cana_?igshid=YmMyMTA2M2Y="><font-awesome-icon icon="fa-brands fa-instagram" size="2xl"/></a>
            <a href="https://www.tiktok.com/@sarl_4cana"><font-awesome-icon icon="fa-brands fa-tiktok" size="2xl"/></a>
            <a href="https://www.facebook.com/profile.php?id=100090580902634"><font-awesome-icon icon="fa-brands fa-facebook" size="2xl"/></a>
        </div>
    </div>
  </div>
</template>

<script>

</script>

<style scoped>
    .footer-container {
        display: flex;
        justify-content: center;
        gap: 200px;
        height: 250px;
        padding: 0px 20px;
        box-shadow: 0px 3px 10px -1px black;
    }

    nav {
        display: flex;
        flex-direction: column;
        gap: 15px;
        font-weight: 700;
    }

    img {
        background-color: #fff;
        width: 300px;
        height: 120px;
        border-radius: 25px;
        box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.5);
        padding: 15px;
    }

    .logos {
        display: flex;
        justify-content: center;
        gap: 15px;
    }

    .mail, img, .social, nav {
        align-self: center;
    }

    a {
        text-decoration: none;
        color: white;
    }

    @media screen and (max-width: 1500px) {
        .footer-container {
            gap: 50px;
        }
    }

    @media screen and (min-width: 580px) and (max-width: 900px){
        .footer-container {
            display: flex;
            flex-wrap: wrap;
            height: 350px;
        }   

        img {
            margin: 10px;
        }
    }

    @media screen and (max-width: 580px) {
        .footer-container {
            display: flex;
            flex-direction: column;
            gap: 0;
            height: 500px;
        }

        nav {
            margin: 15px;
        }
    }
</style>