<template>
  <button >{{ name }}</button>
</template>

<script>
export default {
    props:{
        name: {
            type: String
        }
    }
}
</script>

<style scoped>
    button {
        background-color: #D9D9D9;
        width: 350px;
        height: 60px;
        border: none;
        border-radius: 15px;
        color: black;
        font-size: 25px;
        text-transform: uppercase;
        font-weight: 400;
        box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.5)
    }

    button:hover {
        cursor: pointer;
        transform: scale(.9);
        transition: .3s;
    }
</style>