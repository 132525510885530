<template>
  <div class="realisations-container">
    <Header />
    <h1>Nos réalisations</h1>
    <div class="container">
      <div class="img-container">
        <img :src="require('@/assets/images/Introduction.jpg')" alt="" />
        <div class="caption">Introduction</div>
      </div>
      <div class="img-container">
        <img :src="require('@/assets/images/Fin de conduite.jpg')" alt="" />
        <div class="caption">Fin de conduite</div>
      </div>
      <div class="img-container">
        <img :src="require('@/assets/images/Chemisage DN200 EP.jpg')" alt="" />
        <div class="caption">Chemisage DN200 EP</div>
      </div>
      <div class="img-container">
        <img :src="require('@/assets/images/WhatsApp Image 2023-03-23 at 19.31.32.jpeg')" alt="" />
        <div class="caption">Fraisage et evacuation de racine obstruant un reseau a 100%</div>
      </div>
      <div class="img-container">
        <img :src="require('@/assets/images/WhatsApp Image 2023-03-23 at 19.52.15.jpeg')" alt="" />
        <div class="caption">Fraisage de beton dans un reseau</div>
      </div>
      <div class="img-container">
        <img :src="require('@/assets/images/dcb7dc3e-7494-4866-99a5-9d8bf7b851cd.jpg')" alt="" />
        <div class="caption">Morceau de beton fraisé et extrait a l'aide de notre robot</div>
      </div>
      <div class="img-container">
        <img :src="require('@/assets/images/TEST.jpg')" alt="" />
        <div class="caption">Test</div>
      </div>
      <div class="img-container">
        <img :src="require('@/assets/images/TEST DN 100 - Y+ coude contre coude.jpg')" alt="" />
        <div class="caption">Test DN 100 - Y+ coude contre coude</div>
      </div>
      <div class="img-container">
        <img :src="require('@/assets/images/Résultat.jpg')" alt="" />
        <div class="caption">Résultat</div>
      </div>
      <div class="img-container">
        <img :src="require('@/assets/images/WhatsApp Image 2023-04-19 at 18.37.11.jpeg')" alt="" />
        <div class="caption">Recherche de fuite chez un client à Valflaunès</div>
      </div>
      <div class="img-container">
        <img :src="require('@/assets/images/WhatsApp Image 2023-04-19 at 18.37.12.jpeg')" alt="" />
        <div class="caption">Test pression en cours</div>
      </div>
      <div class="img-container">
        <img :src="require('@/assets/images/WhatsApp Image 2023-04-19 at 18.37.59.jpeg')" alt="" />
        <div class="caption">Recherche de fuite piscine La Rouvière</div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style scoped>
.img-container {
  position: relative;
  width: 500px;
  height: 500px;
  /* object-fit: cover; */
}

.caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 10px;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  align-self: center;
}

.img-container:hover .caption {
  opacity: 1;
}

.img-container:hover {
  cursor: pointer;
}

img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.container {
  display: grid;
  grid-template-columns: repeat(3, 0fr);
  grid-template-rows: repeat(3, 0fr);
  justify-content: center;
  margin: 50px;
}

h1 {
  text-transform: uppercase;
  font-size: 50px;
}

@media screen and (min-width: 500px) and (max-width: 1500px) {
  .container {
    display: grid;
    grid-template-columns: repeat(2, 0fr);
  }
}

@media screen and (max-width: 1000px) {
  .container {
    display: grid;
    grid-template-columns: repeat(1, 0fr);
  }

  .img-container {
    width: 700px;
    height: 700px;
  }
}

@media screen and (max-width: 700px) {
  .img-container {
    width: 375px;
    height: 375px;
  }
}


@media screen and (max-width: 500px) {
  h1 {
    font-size: 40px;
  }
}
</style>