<template>
  <div class="contact-container">
    <Header />
      <h1>Nous contacter</h1>
      <Form />

      <h2>Nous trouver</h2>
      <iframe title="Google Maps Adresse 4Cana" style="border:0" loading="lazy" allowfullscreen src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJvZgpH-CVtRIRNg3Wb4OqQ_o&key=AIzaSyAKVp3OQK7FIe5QImKtBDnbX81osGkXIP4"></iframe> 
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Form from '@/components/Form.vue'
import Footer from '@/components/Footer.vue';


export default {
  components: {
    Header,
    Footer,
    Form
  }
}
</script>

<style scoped>

iframe {
  width: 800px;
  height : 450px;
  border-radius: 25px;
}

@media screen and (max-width: 500px) {
  h1 {
    font-size: 40px;
  }

  iframe {
    width: 100%;
  }
}
</style>