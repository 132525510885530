import {createRouter, createWebHistory} from "vue-router"

import Accueil from "../views/Accueil.vue"
import Equipe from "../views/Equipe.vue"
import Services from "../views/Services.vue"
import Realisations from "../views/Realisations.vue"
import Contact from "../views/Contact.vue"
import Cgv from '../views/CgvData.vue'
import Mentions from '../views/Mentions.vue'
import FormSend from '../views/FormSend.vue'

const routes = [
    {
    name: 'Accueil',
    component: Accueil,
    path : '/'
    },
    {
        name: 'Equipe',
        component: Equipe,
        path: '/equipe'
    },
    {
        name: 'Services',
        component: Services,
        path: '/services'
    },
    {
        name: 'Realisations',
        component: Realisations,
        path: '/realisations'
    },
    {
        name: 'Contact',
        component: Contact,
        path: '/contact'
    },
    {
        name: 'FormSend',
        component: FormSend,
        path: '/formSend'
    },
    {
        name: 'Cgv',
        component: Cgv,
        path: '/cgv'
    },
    {
        name: 'Mentions',
        component: Mentions,
        path: '/mentions'
    }

]

const router = createRouter({
    history : createWebHistory(),
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    }
})

export default router;