<template>
  <Header />
    <h1>Identification</h1>
    <h3>4CANA</h3>
    <p>1Bis Rue du Cros 30150 Montfaucon</p>
    <p>SARL</p>
    <p>SIREN: 949796262</p>
    <p>SIRET: 94979626200010</p>
    <p>TVA Intracommunautaire: FR24949796262</p>
    <p>Téléphone: 06 40 64 26 99</p>
    <p>Email: sarl4cana@gmail.com</p>

    <h3>Hébergeur</h3>
    <p>Le site 4Cana est hébergé par HOSTINGER, dont le siège social est situé à HOSTINGER INTERNATIONAL LTD, 61 Lordou Vironos Street, 6023 Larnaca, Chypre
        et joignable par le moyen suivant: 
        <a>https://www.hostinger.fr/contact</a>
    </p>
  <Footer />
</template>

<script>
import Footer from '../components/Footer.vue'
import Header from '../components/Header.vue'

export default {
    components: {
        Header,
        Footer
    }
}
</script>

<style scoped>
    @media screen and (max-width: 600px) {
        h1 {
            font-size: 40px;
        }
    }
</style>