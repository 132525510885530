<template>
  <Header />
  <h1>Nos services</h1>
  <article>
    <img
      :src="
        require('@/assets/images/Pose manchette sous mobile home évacuation EU DN100.jpeg')
      "
      alt="Pose manchette sous mobile home évacuation EU DN100"
      class="left"
    />
    <div class="description">
      <h3>{{ debouchage.title }}</h3>
      <p>{{ debouchage.text }}</p>
    </div>
  </article>
  <span>____________________________</span>
  <article class="reverse">
    <div class="description">
      <h3>{{ detartrage.title }}</h3>
      <p>{{ detartrage.text }}</p>
    </div>
    <img
      :src="
        require('@/assets/images/Fraisage de dépôt de calcite DN100 sur 15ml.jpg')
      "
      alt="Fraisage de dépôt de calcite DN100 sur 15ml"
      class="right"
    />
  </article>
  <span>____________________________</span>
  <article>
    <img
      :src="
        require('@/assets/images/WhatsApp Image 2023-03-23 at 19.20.31.jpeg')
      "
      alt="Manchette DN 100"
      class="left"
    />
    <div class="description">
      <h3>{{ reparations.title }}</h3>
      <p>{{ reparations.text }}</p>
    </div>
  </article>
  <span>____________________________</span>
  <article class="reverse">
    <div class="description">
      <img
        class="logo"
        :src="require('@/assets/images/décennale.png')"
        alt="Logo garantie décénnale"
      />
      <h3>{{ rehabilitations.title }}</h3>
      <p>{{ rehabilitations.text }}</p>
    </div>
    <img
      :src="
        require('/Users/clement/Desktop/4CanaWebsite/4cana/src/assets/images/Chemisage DN200 EP.jpg')
      "
      alt="Chemisage DN200 EP"
      class="right"
    />
  </article>
  <span>____________________________</span>
  <article>
    <img
      :src="
        require('@/assets/images/WhatsApp Image 2023-04-19 at 18.36.00.jpeg')
      "
      alt="Regard de visite"
      class="left"
    />
    <div class="description">
      <h3>{{ piscine.title }}</h3>
      <p>{{ piscine.text }}</p>
    </div>
  </article>
  <span>____________________________</span>

  <article class="reverse">
    <div class="description">
      <h3>{{ regard.title }}</h3>
      <p>{{ regard.text }}</p>
    </div>
    <img
      :src="
        require('@/assets/images/Capture d’écran 2023-03-23 à 19.30.05.png')
      "
      alt="Regard de visite"
      class="right"
    />
  </article>
  <Footer />
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      debouchage: {
        title: "Débouchage, diagnostic et localisation de canalisations",
        text: "Notre équipe intervient pour déboucher et déterminer l’origine du bouchon. Grâce aux dernières technologies d’inspection vidéo, une caméra est introduite dans la canalisation afin de localiser et de détecter la cause de l'engorgement. Une fois le diagnostic établi, des travaux rapides, garanties et sans destruction vous seront préconisés afin d'éviter les débouchages à répétition ou les risques de dégâts des eaux.",
        image:
          "require('@/assets/images/Pose manchette sous mobile home évacuation EU DN100.jpeg')",
      },
      detartrage: {
        title: "Détartrage de canalisations par fraisage mécanique",
        text: "A l’aide de notre Vortex Cutter et de ses différents accessoires, notre équipe procède au fraisage des dépôts de tartre, béton, sédiments ou pénétration de racines en accédant depuis un regard/tampon de visite, ou depuis l’évacuation du WC une fois celui-ci déposé par nos techniciens.",
      },
      reparations: {
        title: "Réparations ciblées",
        text: "Si la cause du bouchon est due à une fissure, une casse de la canalisation ou une pénétration de racines, une manchette de fibre et de résine silicate est mise en place à l’aide d’un packer de gonflage. Une fois imprégné de résine, la manchette est enroulée autour du packer et mise en place sur l’anomalie par l’intérieur de la conduite. Après gonflage et polymérisation de la manchette en quelques minutes, le packer est dégonflé et retiré, laissant la manchette plaquée contre la conduite afin de lui redonner sa résistance mécanique mais aussi rétablir l’étanchéité de la canalisation.",
      },
      rehabilitations: {
        title:
          "Réhabilitation de canalisations sans tranchée (chemisage continu)",
        text: "Dans le cas d’une conduite présentant une multitude d’anomalies, des défauts structurels, problèmes d’étanchéités ou pour l’isolation d’une conduite en amiante ciment, un chemisage de la canalisation par l’intérieur est possible depuis un accès existant ou créé à un endroit stratégique sur la canalisation. Prestation couverte par la garantie décennale",
      },
      piscine: {
        title:
        "Recherche de fuite sur piscine et réhabilitation sans tranchée",
        text: "La recherche de fuite sur une piscine peut être un processus complexe qui implique la localisation de la source de la fuite. Pour éviter des dommages supplémentaires à la piscine, des dégâts dans votre jardin ou des nuisances, il est possible de réaliser ces réparations sans creuser des tranchées. C'est pourquoi, aujourd'hui, nos équipes ont recours à des techniques de réhabilitation des conduites de filtration sans tranchée. Cette méthode permet de réparer les conduites de filtration de la piscine sans avoir à creuser des tranchées, ce qui réduit les coûts et le temps d'arrêt de la piscine. La méthode de la résine époxy est l'une des techniques les plus courantes pour la réhabilitation des conduites de filtration sans tranchée, pour des conduites d’un diamètre intérieur de 32mm. Cette technique implique l'application d'une résine époxy sur les parois intérieures des conduites de filtration. La résine époxy durcit ensuite, formant une couche résistante qui scelle les fuites et empêche toute infiltration d'eau. Cette méthode peut être utilisée pour réparer des fuites sur les tuyaux, les joints et autres raccords. Une autre technique courante pour la réhabilitation des conduites de filtration sans tranchée est le chemisage continu. Possible depuis un diamètre intérieur de 50mm."
      }
      ,
      regard: {
        title: "Création de regard de visite",
        text: "Certaines conceptions, tels que des Y enterrés, des anomalies structurelles ou des accès inaccessibles sont détectables à l’aide de nos caméras équipées de sonde et permettent ainsi la mise en place de regard ou tampon de visite à un endroit précis.",
      },
    };
  },
};
</script>

<style scoped>
h1 {
  font-size: 50px;
  text-transform: uppercase;
}

span {
  margin: 20px;
}

article {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 30px 0px;
}

.left {
  width: 600px;
  height: 600px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  align-self: center;
  object-fit: cover;
}

.description {
  /* position: relative; */
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
}

.logo {
  position: absolute;
  max-width: 150px;
  right: 0;
  top: 10%;
}

.right {
  width: 600px;
  height: 600px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  align-self: center;
  object-fit: cover;
}

h3 {
  font-size: 30px;
}

p {
  width: 65%;
  align-self: center;
  font-size: 25px;
}

/* @media screen and (max-width: 2000px) {
  .logo {
    position: absolute;
    bottom: 0;
    width: 120px;
  }
}

@media screen and (max-width: 1600px) {
  .logo {
    position: absolute;
    top: 100%;
  }
} */

@media screen and (max-width: 1200px) {
  article {
    display: flex;
    flex-direction: column;
    /* position: relative; */
  }

  .reverse {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
  }

  .left,
  .right {
    border-radius: 0;
  }

  .description {
    width: 100%;
    position: initial;
    margin: 0;
  }

  p {
    width: 90%;
  }

  .logo {
    position: absolute;
    top: 0;
  }
}

@media screen and (max-width: 900px) {
  .left,
  .right {
    width: 100%;
  }

  span {
    display: none;
  }

  .logo {
    position: absolute;
    top: 35%;
  }
}

@media screen and (max-width: 500px) {
  h1 {
    font-size: 40px;
  }
}
</style>