<template>
  <swiper
    :breakpoints="swiperOptions.breakpoints"
    :slidesPerView="3"
    :spaceBetween="30"
    :pagination="{
      clickable: true,
    }"
    :navigation="true"
    :modules="modules"
    class="mySwiper"
  >
    <swiper-slide>
      <img
        :src="
          require('@/assets/images/Fichier 5@3x.png')
        "
        alt="Débouchage, diagnostic et localisation de canalisations"
      />
      <span>Débouchage, diagnostic et localisation de canalisations</span>
    </swiper-slide>
    <swiper-slide>
      <img
        :src="
          require('@/assets/images/piscine.png')
        "
        alt="Recherche de fuite piscine"
      />
      <span>Recherche de fuite piscine</span>
    </swiper-slide>
    <swiper-slide>
      <img
        :src="
          require('@/assets/images/Fichier 3@3x.png')
        "
        alt="Détartrage, fraisage de racines et béton"
      />
      <span>Détartrage, fraisage de racines et béton</span>
    </swiper-slide>
    <swiper-slide>
      <img
        :src="
          require('@/assets/images/Fichier 4@3x.png')
        "
        alt="Réparation ciblée"
      />
      <span>Réparation ciblée</span>
    </swiper-slide>
    <swiper-slide>
      <img
        :src="
          require('@/assets/images/Fichier 2@3x-2.png')
        "
        alt="Chemisage"
      />
      <span>Chemisage</span>
    </swiper-slide>
    <swiper-slide>
      <img
        :src="
          require('@/assets/images/Fichier 1@3x.png')
        "
        alt="Création de regard de visite"
      />
      <span>Création de regard de visite</span>
    </swiper-slide>
  </swiper>

  
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation } from "swiper";

export default {
  data() {
    return {
      swiperOptions: {
        breakpoints: {
          400: {
            slidesPerView: 2,
            spaceBetween: 10
          },
          900: {
            slidesPerView: 3,
            spaceBetween: 30
          }
        }
      }
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Pagination, Navigation],
    };
  },
};
</script>

<style scoped>
.swiper-slide {
  background-color: lightgray;
  color: black;
  border-radius: 25px;
  max-width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

span {
  margin: auto 5px;
  font-size: 18px;
}

.mySwiper {
  width: 900px;
  padding: 10px;
}

img {
  width: 100%;
  height: 60%;
  background-color: #fff;
  border-radius: 25px 25px 0 0;
  object-fit: scale-down;
}

@media screen and (max-width: 950px) {
  .mySwiper {
    width: 550px;
  }

}

@media screen and (max-width: 550px) {
  .mySwiper {
    width: 370px;
    margin: 15px auto;
  }

}
</style>