<template>
  <!-- <div class="equipe_container"> -->
  <Header />
  <h1>Notre équipe</h1>
  <h3>
    Bienvenue chez 4CANA, une entreprise spécialisée dans la réhabilitation de
    canalisations sans tranchées.
  </h3>
  <p>
    Nous sommes une équipe de 4 associés, fort de plus de 15 années
    d’expériences, passionnés par notre métier et engagés à fournir des
    solutions durables et efficaces à nos clients. Nos experts sont fiers de
    proposer des services de pointe dans le domaine de la réhabilitation de
    canalisations sans tranchées. Nous sommes équipés des technologies les plus
    avancées pour assurer une réparation rapide et fiable des
    <strong>canalisations</strong> d’égouts ou
    <strong>systèmes de filtration de piscine</strong>. Chez 4CANA, nous sommes
    conscients des défis rencontrés par les municipalités, les entreprises de
    constructions et les propriétaires fonciers lorsqu’il s’agit de réparer ou
    remplacer des réseaux existants. C’est pourquoi nous nous sommes engagés à
    fournir des services professionnels de qualité, tout en minimisant les
    impacts sur l’environnement, les nuisances et perturbations pour les
    particuliers et communautés locales.
  </p>
  <br /><br />
  <img
    :src="require('../assets/images/image0.jpeg')"
    alt="Photo de l'équipe 4Cana"
  />
  <br /><br />
  <p>
    Notre équipe d’experts chevronnés possède une vaste expérience dans la
    réhabilitation sans tranchées, l’assainissement, les risques liés à
    l’amiante, les travaux en espace confinés ou en hauteur. Nous travaillons
    avec nos clients pour comprendre leurs besoins uniques et proposer des
    solutions sur mesure pour répondre à leurs attentes. Nous sommes fiers de
    notre approche collaborative qui assure une communication claire et
    transparente à toutes les étapes du projet. Chez 4CANA, nous sommes
    déterminés à fournir des services exceptionnels à nos clients tout en
    maintenant des <strong>normes élevées de sécurité et d’excellence</strong>.
    Nous sommes impatients de travailler avec vous pour fournir des solutions
    durables et innovantes à vos défis de
    <strong>réhabilitation de canalisations sans tranchées</strong>.
    Contactez-nous dès aujourd’hui pour en savoir plus sur nos services et
    solutions.
  </p>
  <br />

  <span>L’équipe 4Cana</span>
  <br />
  <br />
  <br />
  <Footer />
  <!-- </div> -->
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style scoped>
.equipe_container {
  background-color: #314257;
}

h1 {
  font-size: 50px;
  text-transform: uppercase;
}

h3 {
  font-size: 30px;
}

p {
  width: 1050px;
  text-align: center;
  margin: 0 auto;
  font-size: 25px;
}

span {
  font-family: "Mr Dafoe", cursive;
  font-size: 45px;
}

@media screen and (max-width: 1100px) {
  h3 {
    width: 100%;
    margin-bottom: 20px;
  }

  p {
    width: 100%;
  }

  img {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  h1 {
    font-size: 40px;
  }
}
</style>