<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #fff;
  margin: 0;
  background-color: #314257;
}

body {
  margin: 0;
}

h1 {
  font-size: 50px;
  text-transform: uppercase;
}

h2 {
  font-size: 35px;
  text-transform: uppercase;
}

h3 {
  font-size: 30px;
}

</style>
