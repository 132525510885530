<template>
  <h3>
    Une question, un renseignement, une demande de devis ? Contactez-nous !
  </h3>
  <form ref="form" @submit.prevent="sendEmail">
    <span class="alert" v-if="alert">Veuillez remplir le formulaire en entier !</span>
    <label>Nom</label>
    <input type="text" name="from_name" placeholder="Nom/Prénom"/>
    <label>Email</label>
    <input type="email" name="user_email" placeholder="Email"/>
    <label>Téléphone</label>
    <input type="text" name="user_phone" placeholder="Téléphone"/>
    <label>Message</label>
    <textarea name="message" placeholder="Votre message..."></textarea>
    <input type="submit" value="Envoyer" />
  </form>
</template>

<script>
import emailjs from "emailjs-com";

export default {
  data() {
    return {
      alert: false
    };
  },
  methods: {
    sendEmail() {
      try {
        if(!this.$refs.form[0].value || !this.$refs.form[1].value || !this.$refs.form[2].value || !this.$refs.form[3].value){
          this.alert = true;
        } else {
          emailjs
            .sendForm(
              "service_xy6u7uh",
              "template_kerk1fa",
              this.$refs.form,
              "3apDFVyzcAxZ8x951"
              )
            .then(
              (result) => {
                console.log("SUCCESS!", result.text);
                if(result.text === 'OK'){
                  this.$router.push('/formSend')
                }
              },
              (error) => {
                console.log("FAILED...", error.text);
              }
            );
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.form-container {
  height: 500px;
}

form {
  display: flex;
  flex-direction: column;
  max-width: 900px;
  align-content: center;
  margin: 0 auto;
}

.alert {
  color: red;
}

.personal-data {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.name,
.email,
.phone {
  width: 300px;
}

.request {
  height: 100px;
}

#photos {
  align-self: center;
}

input[type="submit"] {
  align-self: center;
  width: 200px;
  height: 30px;
  margin-top: 10px;
}
</style>